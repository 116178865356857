import React from 'react';
import Badge from 'react-bootstrap/Badge';

export interface MessagePillProps {
  count: number;
}

export const MessagePill = ({ count }: MessagePillProps) => {
  if (count) {
    return <Badge bg="secondary"> {count} </Badge>;
  } else {
    return <span></span>;
  }
};
