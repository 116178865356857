import './ProfileCard.style.css';

export interface ProfileCardProps {
  numberOfTasks: number;
  amountEarned: number;
}

export const ProfileCard = ({
  numberOfTasks,
  amountEarned,
}: ProfileCardProps) => {
  if (!numberOfTasks) {
    numberOfTasks = 0;
  }

  if (!amountEarned) {
    amountEarned = 0;
  }

  return (
    <div className="container1">
      <div className="card1">
        <div className="card-content">
          <div className="image">
            <img src="https://i.imgur.com/toJNI1l.jpg" alt="profile-pic" />
          </div>
          <div className="text-center">
            <div className="person-name">Test Worker</div>
          </div>
          <div className="stats">
            <div className="stat1">
              <span>{numberOfTasks}</span>
              <span>Tasks</span>
            </div>

            <div className="stat1">
              <span>{formatNumberToCurrency(amountEarned)}</span>
              <span>Earned</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//format number to currency
function formatNumberToCurrency(number: number) {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}
