import { ListGroupItem } from 'react-bootstrap';

import { truncateStringByWidth } from '../../services/utilsService';
import './Message.style.css';

export interface MessageProps {
  id: string;
  topLeft: string;
  topRight: string;
  bottomLeft: string;
  bottomRight: string;
  isActive: boolean;
  handleClick: any;
  maxWidth: number;
}

export function Message({
  id,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  isActive,
  handleClick,
  maxWidth,
}: MessageProps) {
  return (
    <ListGroupItem
      key={id}
      action
      active={isActive}
      className="d-flex flex-column align-items-start"
      onClick={() => handleClick()}
    >
      <div className="message-row">
        <div className="top-left">
          {truncateStringByWidth(topLeft, maxWidth)}
        </div>
        <div className="top-right">{topRight}</div>
      </div>
      <div className="message-row">
        <div className="bottom-left">{bottomLeft}</div>
        <div className="bottom-right">{bottomRight}</div>
      </div>
    </ListGroupItem>
  );
}
