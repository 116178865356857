import { Outlet } from 'react-router-dom';

import { Header } from 'components/Header/Header';
import { SideBar } from 'components/SideBar/SideBar';
import './MemberLayout.style.css';

export function MemberLayout() {
  const sideBarProps = {
    numberOfEmailClassificationTasks: 0,
    amountEarned: 0,
    numberOfTasks: 0,
  };

  return (
    <div id="website-container">
      <div id="body-container">
        <Header />
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
          <SideBar {...sideBarProps} />
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              height: '100vh',
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
