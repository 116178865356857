import { Inbox } from 'components/Inbox/Inbox';

import { useEmailClassificationTasks } from 'hooks/useEmailClassificationTasks';
import { ClassificationTask, JobStatusEnum } from 'libs/types';
import { SingleLabelForm } from 'components/SingleLabelForm/SingleLabelForm';
import { formatISODate } from 'services/utilsService';

export const EmailClassification = () => {
  const {
    statusFilter,
    updateStatusFilter,
    tasks,
    tasksIsLoading,
    tasksError,
    selectedTask,
    selectedTaskIsLoading,
    selectedTaskError,
    updateSelectedTask,
    completeTask,
    completeTaskIsLoading,
    completeTaskError,
  } = useEmailClassificationTasks();

  // create inbox props
  const messages = transformTasksToMessages(
    tasks,
    selectedTask,
    updateSelectedTask,
  );
  const inboxProps = {
    messages,
    isLoading: tasksIsLoading,
    error: tasksError,
    toggleButtons: {
      leftButton: {
        label: JobStatusEnum.IN_PROGRESS,
        isActive: statusFilter === JobStatusEnum.IN_PROGRESS,
        handleClick: () => {
          updateStatusFilter(JobStatusEnum.IN_PROGRESS);
        },
      },
      rightButton: {
        label: JobStatusEnum.COMPLETED,
        isActive: statusFilter === JobStatusEnum.COMPLETED,
        handleClick: () => {
          updateStatusFilter(JobStatusEnum.COMPLETED);
        },
      },
    },
  };

  // create single label form props
  const singleLabelFormProps = {
    task: selectedTask,
    isLoading: selectedTaskIsLoading,
    error: selectedTaskError,
    completeTask,
    completeTaskIsLoading,
    completeTaskError,
  };

  console.log('singleLabelFormProps', singleLabelFormProps);

  return (
    <Inbox {...inboxProps}>
      <SingleLabelForm {...singleLabelFormProps} />
    </Inbox>
  );
};

function transformTasksToMessages(
  tasks: ClassificationTask[],
  selectedTask: ClassificationTask | null,
  handleClick: any,
) {
  return tasks.map((task) => ({
    id: task.classificationTaskId,
    topLeft: task.subject,
    topRight: formatISODate(task.createdOn),
    bottomLeft: task.forUser,
    bottomRight: '',
    isActive: task.classificationTaskId === selectedTask?.classificationTaskId,
    handleClick: () => {
      handleClick(task.classificationTaskId);
    },
    maxWidth: 24,
  }));
}
