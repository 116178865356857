import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
//import { useAuth } from "features/auth/hooks";
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import './Header.style.css';
import React from 'react';
import { IconButton } from 'components/IconButton/IconButton';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';

export function Header(user: any) {
  // const { user } = useAuth();

  return (
    <div id="header">
      <div id="brand">
        <FontAwesomeIcon icon={faBoxOpen} size="1x" />
        <span style={{ marginLeft: 8 }}>
          BillButler.ai &gt; <Breadcrumbs />
        </span>
      </div>
      <div className="sign-in-out-container">
        {user != null ? (
          <IconButton
            icon={faSignOutAlt}
            label="Sign Out"
            isActive={false}
            handleClick={() => console.log('clicked')}
          />
        ) : (
          <span>(not logged in)</span>
        )}
      </div>
    </div>
  );
}
