export const LIST_CLASSIFICATION_TASKS_BY_STATUS_QUERY = `
query listClassificationTasksByStatus ($status: JobStatusEnum!, $type: TaskType!) {
  listClassificationTasksByStatus(status: $status, type: $type) {
    classificationTaskId
    createdOn
    status
    type
    classes
    classificationResult {
      class
      confidenceScore
    }
    subject
    forUser
  }
}
`;

export const GET_CLASSIFICATION_TASK_QUERY = `
query getClassificationTask($classificationTaskId: String!) {
  getClassificationTask(classificationTaskId: $classificationTaskId) {
    classes
    classificationResult {
      class
      confidenceScore
    }
    classificationTaskId
    createdOn
    status
    type
    version
    html
    preprocessedText
    text
    subject
    forUser
  }
}
  `;

export const COMPLETE_CLASSIFICATION_TASK_MUTATION = `
mutation completeClassificationTask($classification: String!,  $classificationTaskId: String!, $score: Float!) {
  completeClassificationTask(classification: $classification, classificationTaskId: $classificationTaskId, score: $score)
}
`;
