import { AuthProvider } from 'features/auth/contexts';
import { Router } from 'features/navigation/Router/Router';
import { Amplify } from 'aws-amplify';
import config from './config';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

//TODO conver to aws sdk 3
Amplify.configure({
  Auth: {
    region: config.region,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  },
  API: {
    aws_appsync_graphqlEndpoint: config.aws_appsync_graphqlEndpoint,
    aws_appsync_authenticationType: config.aws_appsync_authenticationType,
    graphql_endpoint_iam_region: config.graphql_endpoint_iam_region,
  },
});

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="App">
        <AuthProvider>
          <Router />
        </AuthProvider>
      </div>
    </LocalizationProvider>
  );
}

export default App;
