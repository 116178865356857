import React, { useState } from 'react';
import { useAuth } from 'features/auth/hooks';
import { useNavigate, useLocation } from 'react-router-dom';

const ConfirmSignUp = () => {
  const location: any = useLocation();
  const username = location?.state?.username;

  function setInput(key: string, value: string) {
    setFormState({ ...formState, [key]: value, isLoginFailure: false });
  }

  const { user, confirmSignUp } = useAuth();
  const navigate = useNavigate();

  const initialFormValues = {
    username: username,
    authCode: '',
    isLoginFailure: false,
  };
  const [formState, setFormState] = useState(initialFormValues);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    //TODO add validation
    const username = formState.username;
    const authCode = formState.authCode;
    console.log('username: ', username);
    const response = await confirmSignUp(username, authCode);
    console.log('Response = ', response);
    console.log('username: ', username);
    navigate('/auth/login', { state: { username: username } });
  };

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Confirm Registration</h3>
          <div className="form-group mt-3">
            <label>Auth Code</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Enter auth code"
              onChange={(event) => setInput('authCode', event.target.value)}
              value={formState.authCode}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export { ConfirmSignUp };
