import { API, graphqlOperation } from 'aws-amplify';

import {
  JobStatusEnum,
  ClassificationTask,
  TaskType,
  CompleteTaskInput,
} from '../libs/types';
import {
  LIST_CLASSIFICATION_TASKS_BY_STATUS_QUERY,
  GET_CLASSIFICATION_TASK_QUERY,
  COMPLETE_CLASSIFICATION_TASK_MUTATION,
} from '../libs/constants';

export const getTasks = async (status: JobStatusEnum, type: TaskType) => {
  const variables = { status, type };
  const tasks = (
    (await fetchGraphqlData(
      LIST_CLASSIFICATION_TASKS_BY_STATUS_QUERY,
      variables,
    )) as any
  ).listClassificationTasksByStatus as ClassificationTask[];
  return tasks;
};

export const getTask = async (taskId: string) => {
  const variables = { classificationTaskId: taskId };

  const task = (
    (await fetchGraphqlData(GET_CLASSIFICATION_TASK_QUERY, variables)) as any
  ).getClassificationTask as ClassificationTask;

  return task;
};

export const completeTask = async (input: CompleteTaskInput) => {
  const variables = { ...input };

  console.log('variables', variables);
  const result = (await fetchGraphqlData(
    COMPLETE_CLASSIFICATION_TASK_MUTATION,
    variables,
  )) as any;
  return result;
};

const fetchGraphqlData = async (query: string, variables: any) => {
  try {
    console.log('variables', variables);

    const gqlOp = graphqlOperation(query, variables);
    console.log('gqlOp', gqlOp);

    const response: any = await API.graphql(gqlOp);

    console.log('response', response);

    const data = response.data;
    return data;
  } catch (err) {
    console.log('error executing graphql query', query, variables);
    console.log('error executing graphql', err);
    throw new Error('error fetching graphql data');
  }
};
