export type ClassificationResult = {
  confidenceScore: number;
  classifiedBy: string;
};

export type ClassificationTask = {
  classificationTaskId: string;
  type: TaskType;
  status: JobStatusEnum;
  classes: string[];
  classificationResult?: ClassificationResult;
  createdOn: string;
  version: number;
  preprocessedText: string;
  text?: string;
  html?: string;
  subject: string;
  forUser: string;
};

export enum TaskType {
  HUMAN = 'HUMAN',
  AUTOMATED = 'AUTOMATED',
}

export enum JobStatusEnum {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface CompleteTaskInput {
  classificationTaskId: string;
  classification: string;
  score: number;
}
