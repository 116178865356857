const config = {
  region: 'us-east-1',

  userPoolId: 'us-east-1_7iSwKM4Og',
  userPoolWebClientId: '3l1ci7nn64g4r983sd15dkr8ke',

  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  graphql_endpoint_iam_region: 'us-east-1',
  aws_appsync_graphqlEndpoint:
    'https://uqgxbarxundehijrpeoin4chv4.appsync-api.us-east-1.amazonaws.com/graphql',
};

export default config;
