import React from 'react';
import { Button } from 'components/Button/Button';

import './SingleLabelForm.style.css';
import { ClassificationTask, CompleteTaskInput } from 'libs/types';
import { IconButton } from 'components/IconButton/IconButton';

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

interface SingleLabelFormProps {
  task: ClassificationTask | null;
  isLoading: boolean;
  error: string | null;
  completeTask: any;
  completeTaskIsLoading: boolean;
  completeTaskError: string | null;
}

export const SingleLabelForm = ({
  task,
  isLoading,
  completeTask,
}: SingleLabelFormProps) => {
  console.log('task', task);

  if (task === null || task === undefined) {
    return <span>No Task Selected</span>;
  } else {
    return (
      <React.Fragment>
        <div className="action-box">
          <span className="d-flex flex-row">
            <span>&nbsp;&nbsp;</span>
            {task.classes?.map((classification: string) => (
              <>
                <Button
                  buttonText={classification}
                  variant="primary"
                  key={classification}
                  onClick={() => {
                    console.log(
                      'Button clicked for classification:',
                      classification,
                    );
                    completeTask({
                      classificationTaskId: task.classificationTaskId,
                      classification,
                      score: 1,
                    });
                  }}
                />
                <span>&nbsp;&nbsp;</span>
              </>
            ))}
          </span>
        </div>

        {/* <div id="summary" style={{ padding: 5 }}>
          <div className="my-row">
            <div className="email-subject">{subject}</div>
            <div>
              {new Date(Date.parse(receivedDate)).toLocaleDateString('en-US')}
            </div>
          </div>
          <div className="my-row">
            <div>From: {fromAddress}</div>
          </div>
          <div className="my-row">
            <div>To: {toAddress}</div>
            <div></div>
          </div>
        </div> */}

        <iframe
          title="Input Content"
          id="detail"
          srcDoc={task.html}
          sandbox="allow-same-origin allow-popups"
        />
      </React.Fragment>
    );
  }
};
