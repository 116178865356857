// export const formatISODate = (isoDate: string) => {
//   const date = new Date(isoDate);
//   const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
//   return date.toLocaleDateString(undefined, options);
// };

export function formatISODate(isoDateString: string): string {
  const date = new Date(isoDateString);
  const today = new Date();

  // If the date is the same as today, show the hour and minute with AM/PM
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${ampm}`;
  }

  // If the date is within 7 days of today, show the day of the week
  const dayDifference = Math.floor(
    (date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24),
  );
  if (dayDifference >= -6 && dayDifference <= 0) {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return daysOfWeek[date.getDay()];
  }

  // If the date is older than 7 days, show the day and month
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  return `${month}/${day}`;
}

export const truncateStringByWidth = (
  inputString: string,
  maxWidth: number,
) => {
  // Create a hidden div to measure the string width
  const hiddenDiv = document.createElement('div');
  hiddenDiv.style.visibility = 'hidden';
  hiddenDiv.style.position = 'absolute';
  hiddenDiv.style.whiteSpace = 'nowrap';
  hiddenDiv.style.fontSize = '24px'; // You can adjust the font size as needed

  // Append the hidden div to the body
  document.body.appendChild(hiddenDiv);

  // Set the string as the content of the hidden div
  hiddenDiv.textContent = inputString;

  // Check if the string width exceeds the maxWidth
  if (hiddenDiv.offsetWidth > maxWidth) {
    let truncatedString = inputString;
    while (hiddenDiv.offsetWidth > maxWidth && truncatedString.length > 0) {
      truncatedString = truncatedString.slice(0, -1);
      hiddenDiv.textContent = truncatedString + '...'; // Add ellipsis
    }
    document.body.removeChild(hiddenDiv); // Remove the hidden div
    return truncatedString + '...'; // Return the truncated string with ellipsis
  }

  document.body.removeChild(hiddenDiv); // Remove the hidden div
  return inputString; // Return the original string if it fits within maxWidth
};
