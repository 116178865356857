import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Nav from 'react-bootstrap/Nav';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const Breadcrumbs = (props: any) => {
  let location = useLocation();
  let pathname = location.pathname;
  let navigate = useNavigate();
  let params = useParams();

  const pathnames = pathname.split('/').filter((x) => x);
  return (
    <span
      style={{
        fontSize: 13,
        fontWeight: 500,
        textDecoration: 'normal',
      }}
    >
      {pathnames.map((name, index) => {
        //console.log(pathnames);
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <span key={name}> {formatString(name)}</span>
        ) : (
          <span></span>
        );
      })}
    </span>
  );
};

function formatString(input: string): string {
  // Replace dashes with spaces
  let stringWithSpaces = input.replace(/-/g, ' ');

  // Capitalize the first letter of each word
  let formattedString = stringWithSpaces.replace(/\b\w/g, (match) =>
    match.toUpperCase(),
  );

  return formattedString;
}
