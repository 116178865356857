import { FilteredMessageList } from 'components/FilteredMessageList/FilteredMessageList';
import { ToggleButtonProps } from 'components/ToggleButton/ToggleButton';
import { MessageProps } from 'components/Message/Message';
import './Inbox.style.css';

export interface InboxProps {
  children: React.JSX.Element;
  messages: MessageProps[];
  isLoading: boolean;
  error: string | null;
  toggleButtons: ToggleButtonProps;
}

export const Inbox = ({
  children,
  messages,
  isLoading,
  error,
  toggleButtons,
}: InboxProps) => {
  return (
    <div id="content">
      <div id="message-container">
        <FilteredMessageList
          messages={messages}
          isLoading={isLoading}
          error={error}
          toggleButtons={toggleButtons}
        />
      </div>
      <div id="info-container">{children}</div>
    </div>
  );
};
