import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'features/auth/hooks';

const LandingPage = () => {
  const { isLoading, isAdmin, isMember, isCustomer } = useAuth();

  return isLoading ? (
    <div>Loading</div>
  ) : isAdmin ? (
    <Navigate to="/admin" replace />
  ) : isMember ? (
    <Navigate to="/members" replace />
  ) : isCustomer ? (
    <Navigate to="/customers" replace />
  ) : (
    <div>No Group Assigned</div>
  );
};

export { LandingPage };
