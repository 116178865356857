import React, { useState } from 'react';
import { useAuth } from 'features/auth/hooks';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import './Login.style.css';

function Login() {
  const { login, user, isLoading, isAdmin, isMember, isCustomer } = useAuth();
  const location: any = useLocation();
  console.log('location: ', location);
  const username = location.state?.username;

  const initialFormValues = {
    loginUsername: username ? username : '',
    loginPassword: '',
    isLoginFailure: false,
  };
  const [formState, setFormState] = useState(initialFormValues);
  const navigate = useNavigate();

  const handleLogin = async (event: any) => {
    try {
      console.log('entered handleLogin');
      event.preventDefault();
      event.target.className += ' was-validated';
      var form = event.target;
      var isValidForm = form.checkValidity();
      if (isValidForm) {
        const { loginUsername, loginPassword } = formState;
        const response = await login(loginUsername, loginPassword);
        console.log('successful login response', response);
        if (location.state?.from) {
          navigate(location.state?.from);
        } else {
          //navigate to landing page which will route based on role
          navigate('/auth/landingpage');
        }
      } else {
        console.log('login form not valid');
      }
    } catch (err) {
      console.log('error logging in', err);
      //TODO handle incorrect password case
      setFormState({ ...initialFormValues, isLoginFailure: true });
    }
  };

  function setInput(key: string, value: string) {
    setFormState({ ...formState, [key]: value, isLoginFailure: false });
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={handleLogin}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="text-center">
            Not registered yet? <Link to="/auth/signup">Sign Up</Link>
          </div>
          <div className="form-group mt-3">
            <label>Username</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Enter username"
              onChange={(event) =>
                setInput('loginUsername', event.target.value)
              }
              value={formState.loginUsername}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={(event) =>
                setInput('loginPassword', event.target.value)
              }
              value={formState.loginPassword}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="text-center mt-2">
            Forgot <a href="#">password?</a>
          </p>
        </div>
      </form>
    </div>
  );
}

export { Login };
