import React, { useState } from 'react';
import './ToggleButton.style.css';

export interface ToggleButtonProps {
  leftButton: {
    label: string;
    handleClick: () => void;
    isActive: boolean;
  };
  rightButton: {
    label: string;
    handleClick: () => void;
    isActive: boolean;
  };
}

export const ToggleButton = ({
  leftButton,
  rightButton,
}: ToggleButtonProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked((prevIsChecked) => !prevIsChecked);

    if (!isChecked) {
      rightButton.handleClick();
    } else {
      leftButton.handleClick();
    }
  };

  return (
    <React.Fragment>
      <div className="btn-container">
        <label className="switch btn-color-mode-switch">
          <input
            type="checkbox"
            name="color_mode"
            id="color_mode"
            value="1"
            checked={isChecked}
            onChange={handleChange}
          />
          <label
            data-on={rightButton.label}
            data-off={leftButton.label}
            className="btn-color-mode-switch-inner"
          ></label>
        </label>
      </div>
    </React.Fragment>
  );
};
