import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import './IconButton.style.css';

interface IconButtonProps {
  icon: IconDefinition;
  handleClick: () => void;
  isActive: boolean;
  label: string;
}

export const IconButton = ({
  icon,
  handleClick,
  isActive,
  label,
}: IconButtonProps) => {
  return (
    <Button
      variant={isActive ? 'primary' : 'outline-primary'}
      size="sm"
      onClick={handleClick}
      className="icon-button"
    >
      <FontAwesomeIcon className="icon" icon={icon} />
      {label}
    </Button>
  );
};
