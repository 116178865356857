import { Button as BootstrapButton } from 'react-bootstrap';
import { ButtonProps as BootstrapButtonProps } from 'react-bootstrap/Button';

interface ButtonProps extends BootstrapButtonProps {
  buttonText: string;
  size?: 'sm' | 'lg';
  variant:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | 'link';
  onClick: () => void;
}

export const Button = ({ buttonText, variant, size, onClick }: ButtonProps) => {
  return (
    <BootstrapButton variant={variant} size={size} onClick={() => onClick()}>
      {buttonText}
    </BootstrapButton>
  );
};
