import React from 'react';
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  //  useProSidebar,
  //  ProSidebarProvider,
} from 'react-pro-sidebar';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import { HiHome } from 'react-icons/hi';

import { MessagePill } from 'components/MessagePill/MessagePill';
import './SideBar.style.css';
import { ProfileCard } from 'components/ProfileCard/ProfileCard';

export interface SideBarProps {
  numberOfEmailClassificationTasks: number;
  amountEarned: number;
  numberOfTasks: number;
}

export const SideBar = ({
  numberOfEmailClassificationTasks,
  amountEarned,
  numberOfTasks,
}: SideBarProps) => {
  // const { collapseSidebar } = useProSidebar();

  return (
    <Sidebar>
      <Menu>
        <ProfileCard
          amountEarned={amountEarned}
          numberOfTasks={numberOfTasks}
        />
        <MenuItem
          // routerLink={<Link to="/members/" />}
          icon={<HiHome size={22} />}
        >
          Home
        </MenuItem>
        <SubMenu
          icon={<BsFillPersonPlusFill size={22} />}
          label="Human In Loop"
          open={true}
        >
          <MenuItem
          // routerLink={<Link to="/members/classify-emails" />}
          >
            Classify Email{' '}
            <MessagePill count={numberOfEmailClassificationTasks} />
          </MenuItem>
        </SubMenu>
      </Menu>
    </Sidebar>
  );
};
