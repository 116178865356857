import React, { useEffect } from 'react';
import { useAuth } from 'features/auth/hooks';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    logout();
    navigate('/');
  }, []);
  return <div>You have been logged out</div>;
};

export { Logout };
