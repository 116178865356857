import { useState, useEffect } from 'react';

import {
  getTasks,
  getTask,
  completeTask as apiCompleteTask,
} from '../services/apiService';
import {
  ClassificationTask,
  JobStatusEnum,
  TaskType,
  CompleteTaskInput,
} from '../libs/types';

export function useEmailClassificationTasks() {
  const [statusFilter, setStatusFilter] = useState<JobStatusEnum>(
    JobStatusEnum.IN_PROGRESS,
  );

  const [tasks, setTasks] = useState<ClassificationTask[]>([]);
  const [tasksIsLoading, setTasksIsLoading] = useState<boolean>(true);
  const [tasksError, setTasksError] = useState<string | null>(null);

  const [selectedTask, setSelectedTask] = useState<ClassificationTask | null>(
    null,
  );
  const [selectedTaskIsLoading, setSelectedTaskIsLoading] =
    useState<boolean>(true);
  const [selectedTaskError, setSelectedTaskError] = useState<string | null>(
    null,
  );

  const [completeTaskIsLoading] = useState<boolean>(false);
  const [completeTaskError, setCompleteTaskError] = useState<string | null>(
    null,
  );

  useEffect(() => {
    const fetchTasks = async (statusFilter: JobStatusEnum) => {
      try {
        const tasksData = await getTasks(statusFilter, TaskType.HUMAN);
        setTasks(tasksData);
        setTasksIsLoading(false);
        if (tasksData.length > 0) {
          updateSelectedTask(tasksData[0].classificationTaskId);
        }
      } catch (err) {
        // setTasksError(err.message);
        setTasksIsLoading(false);
      }
    };

    fetchTasks(JobStatusEnum.IN_PROGRESS);
  }, []);

  const updateStatusFilter = async (statusFilter: JobStatusEnum) => {
    setStatusFilter(statusFilter);
    const tasksData = await getTasks(statusFilter, TaskType.HUMAN);
    setTasks(tasksData);

    // if selected task is not in new list, set selected task to first task in list
    const selectedTaskIndex = tasksData.findIndex(
      (task) =>
        task.classificationTaskId === selectedTask?.classificationTaskId,
    );
    if (selectedTaskIndex === -1 && tasksData.length > 0) {
      updateSelectedTask(tasksData[0].classificationTaskId);
    }
  };

  const updateSelectedTask = async (selectedTaskId: string) => {
    try {
      if (selectedTaskId !== null) {
        const selectedTaskData = await getTask(selectedTaskId);
        setSelectedTask(selectedTaskData);
        setSelectedTaskIsLoading(false);
      }
    } catch (err) {
      // setSelectedTaskError(err.message);
      setSelectedTaskIsLoading(false);
    }
  };

  const completeTask = async (input: CompleteTaskInput) => {
    console.log('completeTask', input);
    try {
      const response = await apiCompleteTask(input);
      console.log('response', response);

      // remove task from list
      const updatedTasks = tasks.filter(
        (task) => task.classificationTaskId !== input.classificationTaskId,
      );
      setTasks(updatedTasks);

      // update selected task to next task in list based on index after selected task is removed
      const selectedTaskIndex = tasks.findIndex(
        (task) => task.classificationTaskId === input.classificationTaskId,
      );
      if (selectedTaskIndex !== -1) {
        const nextTaskIndex = selectedTaskIndex + 1;
        if (nextTaskIndex < tasks.length) {
          updateSelectedTask(tasks[nextTaskIndex].classificationTaskId);
        } else {
          // it is minus 2 as length has offset of 1 plus we removed an element
          updateSelectedTask(tasks[tasks.length - 2].classificationTaskId);
        }
      }
    } catch (err) {
      console.log('err in complete task', err);
      //setCompleteTaskError(err.message);
    }
  };

  return {
    tasks,
    tasksIsLoading,
    tasksError,
    statusFilter,
    updateStatusFilter,
    selectedTask,
    updateSelectedTask,
    selectedTaskIsLoading,
    selectedTaskError,
    completeTask,
    completeTaskIsLoading,
    completeTaskError,
  };
}
