import { useEffect, useRef, useState } from 'react';
import { ListGroup } from 'react-bootstrap';

import { Message, MessageProps } from 'components/Message/Message';
import './MessageList.style.css';

export interface MessageListProps {
  messages: MessageProps[];
  isLoading: boolean;
  error: string | null;
}

export const MessageList = ({
  isLoading,
  messages,
  error,
}: MessageListProps) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    // Function to update the container width
    const updateContainerWidth = () => {
      if (containerRef.current) {
        // @ts-ignore
        const width = containerRef.current.clientWidth;
        setContainerWidth(width);
      }
    };

    // Add event listener to window resize
    window.addEventListener('resize', updateContainerWidth);

    // Initial width update
    updateContainerWidth();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  return (
    <div id="message-list-container" ref={containerRef}>
      {isLoading ? (
        <div className="exception-container">Loading</div>
      ) : error ? (
        <div className="exception-container">{error}</div>
      ) : (
        <ListGroup>
          {messages?.length > 0 ? (
            messages.map((message) => (
              <Message
                key={message.id}
                {...message}
                maxWidth={containerWidth}
              />
            ))
          ) : (
            <span> No Messages </span>
          )}
        </ListGroup>
      )}
    </div>
  );
};
