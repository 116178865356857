import React, { useContext } from 'react';
import { AuthContext } from 'features/auth/contexts/AuthContext';

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(
      '`useAuth` hook must be used within a `UserProvider` component',
    );
  }
  return context;
};
