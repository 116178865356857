import React from 'react';
import { Header } from 'components/Header/Header';
import './AuthLayout.style.css';
import { Outlet } from 'react-router-dom';
import { Login } from 'features/auth/pages';

export function AuthLayout() {
  return (
    <div id="website-container">
      <div id="body-container">
        <Header />
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
