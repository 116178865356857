import { MessageList } from 'components/MessageList/MessageList';
import { MessageProps } from 'components/Message/Message';
import './FilteredMessageList.style.css';
import {
  ToggleButton,
  ToggleButtonProps,
} from 'components/ToggleButton/ToggleButton';

export interface FilteredMessageListProps {
  messages: MessageProps[];
  isLoading: boolean;
  error: string | null;
  toggleButtons: ToggleButtonProps;
}

export const FilteredMessageList = ({
  messages,
  isLoading,
  error,
  toggleButtons,
}: FilteredMessageListProps) => {
  return (
    <div id="list-container">
      <div id="message-filter">
        <ToggleButton {...toggleButtons} />
        <div id="filter-hamburger"></div>
      </div>
      <MessageList messages={messages} isLoading={isLoading} error={error} />
    </div>
  );
};
