import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Header } from './components/Header/Header';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from './components/IconButton/IconButton';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './custom.scss';

//import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    {/* <IconButton
      icon={faSignOutAlt}
      label="Sign Out"
      isActive={false}
      handleClick={() => console.log('clicked')}
    /> */}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
