import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';

import {
  SignUp,
  ConfirmSignUp,
  Login,
  Logout,
  LandingPage,
} from 'features/auth/pages';
import { AuthLayout } from 'features/auth/components';

import { MemberLayout } from 'components/MemberLayout/MemberLayout';
import { EmailClassification } from 'pages/EmailClassification/EmailClassification';
import { useAuth } from 'features/auth/hooks';

const Router = () => {
  return (
    <ProSidebarProvider>
      <BrowserRouter>
        <Routes>
          <Route path="auth" element={<AuthLayout />}>
            <Route path="signup" element={<SignUp />} />
            <Route path="confirmsignup" element={<ConfirmSignUp />} />
            <Route path="login" element={<Login />} />
            <Route path="landingpage" element={<LandingPage />} />
            <Route path="logout" element={<Logout />} />
          </Route>

          <Route
            path="members"
            element={
              <MemberRoute>
                <MemberLayout />
              </MemberRoute>
            }
          >
            <Route path="classify-emails" element={<EmailClassification />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ProSidebarProvider>
  );
};

export { Router };

export interface RouteProps {
  children: React.ReactNode;
}

export function MemberRoute({ children }: any) {
  const { isLoading, user, isMember } = useAuth();

  const location = useLocation();
  return isLoading ? (
    <div>Loading</div>
  ) : !user ? (
    <Navigate to="/auth/login" replace state={{ from: location }} />
  ) : isMember ? (
    children
  ) : (
    <div>Not Authorized - Not Member</div>
  );
}
