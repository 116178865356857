import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

// Create a context that will hold the values that we are going to expose to our components.
// Don't worry about the `null` value. It's gonna be *instantly* overriden by the component below

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<any | null>(null);

// Create a "controller" component that will calculate all the data that we need to give to our
// components bellow via the `UserContext.Provider` component. This is where the Amplify will be
// mapped to a different interface, the one that we are going to expose to the rest of the app.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [isCustomer, setIsCustomer] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const updateUserAttributes = (currentAuthenticatedUser: any) => {
    setIsLoading(true);
    setUser({ ...currentAuthenticatedUser });

    // TODO fix this hack
    setIsMember(true);
    setIsCustomer(false);

    if (
      currentAuthenticatedUser.signInUserSession.accessToken.payload[
        'cognito:groups'
      ]
    ) {
      setIsMember(
        currentAuthenticatedUser.signInUserSession.accessToken.payload[
          'cognito:groups'
        ].includes('member'),
      );
      setIsAdmin(
        currentAuthenticatedUser.signInUserSession.accessToken.payload[
          'cognito:groups'
        ].includes('admin'),
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // attempt to fetch the info of the user that was already logged in
    async function setCurrentUser() {
      try {
        const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
        updateUserAttributes(currentAuthenticatedUser);
      } catch (err) {
        setUser(null);
        setIsCustomer(false);
        setIsMember(false);
        setIsAdmin(false);
        setIsLoading(false);
        console.log('error in setCurrentUser: TODO handle this better');
      }
    }
    setCurrentUser();
  }, []);

  const login = async (usernameOrEmail: string, password: string) => {
    try {
      const cognitoUser = await Auth.signIn(usernameOrEmail, password);
      updateUserAttributes(cognitoUser);
      console.log('setting user from AuthContext.Login: ', cognitoUser);
      return cognitoUser;
    } catch (err: any) {
      if (err.code === 'UserNotFoundException') {
        err.message = 'Invalid username or password';
      }
      throw err;
    }
  };

  const logout = async () => {
    try {
      const data = await Auth.signOut();
      setUser(null);
      return data;
    } catch (err: any) {
      console.log(err);
      throw err;
    }
  };

  const signUp = async (
    username: string,
    password: string,
    email: string,
    phone_number: string,
  ) => {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: { email, phone_number },
      });

      return user;
    } catch (error: any) {
      console.log('error signing up:', error);
      throw error;
    }
  };

  const confirmSignUp = async (username: string, authCode: string) => {
    try {
      const response = await Auth.confirmSignUp(username, authCode);
      console.log('response: ', response);
      //updateUserAttributes(user);
      return response;
    } catch (err) {
      console.log('error confirming signup', err);
      throw err;
    }
  };

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` value has changed. This is an optimisation that is mostly needed in cases
  // where the parent of the current component re-renders and thus the current component is forced
  // to re-render as well. If it does, we want to make sure to give the `UserContext.Provider` the
  // same value as long as the user data is the same. If you have multiple other "controller"
  // components or Providers above this component, then this will be a performance booster.
  //const values = React.useMemo(() => ({ user, login, logout, signUp, confirmSignUp }), [user]);
  const values = {
    user,
    isCustomer,
    isMember,
    isAdmin,
    isLoading,
    login,
    logout,
    signUp,
    confirmSignUp,
  };

  // Finally, return the interface that we want to expose to our other components
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
