import React, { useState } from 'react';
import { useAuth } from 'features/auth/hooks';
import './SignUp.style.css';
import { useNavigate, Link } from 'react-router-dom';

const SignUp = () => {
  const initialFormState = {
    username: '',
    password: '',
    email: '',
    phoneNumber: '',
  };
  const [formState, setFormState] = useState(initialFormState);

  function setInput(key: string, value: string) {
    setFormState({ ...formState, [key]: value });
  }

  const { signUp } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    //TODO add validation
    const username = formState.username;
    const password = formState.password;
    const email = formState.email;
    const phoneNumber = formState.phoneNumber;

    const user = await signUp(username, password, email, phoneNumber);
    console.log('Login: user = ', user);

    navigate('/auth/confirmsignup', { state: { username: username } });
  };

  return (
    <div>
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Register</h3>

            <div className="form-group mt-3">
              <label>Username</label>
              <input
                type="text"
                className="form-control mt-1"
                placeholder="e.g Jane123"
                onChange={(event) => setInput('username', event.target.value)}
                value={formState.username}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="password"
                onChange={(event) => setInput('password', event.target.value)}
                value={formState.password}
              />
            </div>
            <div className="form-group mt-3">
              <label>Email Address</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="email@gmail.com"
                onChange={(event) => setInput('email', event.target.value)}
                value={formState.email}
              />
            </div>
            <div className="form-group mt-3">
              <label>Phone Number</label>
              <input
                type="phone"
                className="form-control mt-1"
                placeholder="(555) 555-5555"
                onChange={(event) =>
                  setInput('phoneNumber', event.target.value)
                }
                value={formState.phoneNumber}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <div className="text-center" style={{ marginTop: 25 }}>
              Already registered? <Link to="/auth/login">Login</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { SignUp };
